import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";
// import ButtonLink from "../Button/Button";

const LeftImageWithCta2 = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="bg-white pt-17 pb-17 md:pt-0 md:pb-26 content__left-img-with-cta2">
      <div className="mx-auto w-full max-w-7xl px-7">
        <div className="md:grid md:grid-cols-2 md:gap-20 text-blue">
          <div className="animate slide-from-left ease-in-out w-50 mx-auto mb-10 md:mb-0 md:mx-0 md:w-full md:max-w-100">
            <img src={props.data.image[0]} alt="" />
          </div>
          <div className="animate slide-from-right ease-in-out">
            <strong className="block text-center md:text-left mb-4">
              <span className="block mb-4 text-xs md:text-lg">{ props.data.subtitle }</span>
              <span className="text-sm md:text-lg">{ props.data.title }</span>
            </strong>
            <p className="text-xs leading-5 md:leading-normal md:text-sm text-center md:text-left mb-6 font-medium">{ props.data.description }</p>
            <div className="hidden md:block">
              <a href={props.data.cta.url} target="_blank" rel="noreferrer" className="inline-block border border-blue rounded-3xl px-5 py-2.5 mr-2 last:mr-0 text-white bg-blue font-bold font-sans text-sm hover:bg-bluepurple hover:border-bluepurple active:bg-white active:text-blue active:border-blue">
                {props.data.cta.text}
              </a>
            </div>
            <div className="text-center md:hidden">
              <a href={props.data.cta.url} target="_blank" rel="noreferrer" className="inline-block border border-blue rounded-3xl px-5 py-2.5 mr-2 last:mr-0 text-white bg-blue font-bold font-sans text-sm hover:bg-bluepurple hover:border-bluepurple active:bg-white active:text-blue active:border-blue">
                Learn More
              </a>
            </div>
            {/* <a href={props.data.cta.url} target="_blank" rel="noreferrer">{props.data.cta.text}</a> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeftImageWithCta2;